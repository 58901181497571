<template>
  <div class="shop-wrapper">
    <div class="shop_banner">
      <img :src="SubDetail.sub_desc_img" alt="">
      <span @click="onClickPageLink('http://uri.amap.com/navigation?to='+SubDetail.jump_url+','+SubDetail.sub_name)"></span>
    </div>
    <div class="_bottom_fixed">
      <div class="_height"></div>
      <div class="_bottom_back">
        <span class="_back_home" @click="$router.push('/home?typ='+MerchantDetail.card_id)"></span>
        <span class="_back_prev" @click="$router.go(-1)"></span>
      </div>
    </div>
    <div class="_right_bar_new_card" @click="onClickPageLink('https://wx.scbank.cn/ares-web-wechat/wechat/authinit/applyDebitCard.do')"  >
      <img src="http://pic.eaworks.cn/scbank/uploads/2023922/6294112.png" >
    </div>
  </div>
</template>

<script>
import page_links from "../mixin/page_links";
import wx from "weixin-js-sdk";
export default {
  mixins: [page_links],
  components: {},
  data() {
    return {
      MerchantDetail:{},
      SubDetail:{}
    };
  },

  mounted() {
    this.getMerchantDetail();
    this.getJsInfo();
  },

  methods: {
    async getJsInfo(){
      // 生成网页js签名
      let {data,code} = await this.$ajax.gateway("/api/admin_get_js_sign", {
        "body": {
          "url": location.href.split('#')[0]
        }
      });
      if(code===200){
        data.res["jsApiList"] = [
          'checkJsApi',
          'updateTimelineShareData',
          'updateAppMessageShareData',
          'onMenuShareTimeline',
          'onMenuShareAppMessage',
          'hideAllNonBaseMenuItem',
          'showAllNonBaseMenuItem'
        ];
        wx.config(data.res);
      }
      wx.ready(function( ){
        wx.updateTimelineShareData({
          title: '四川银行-安逸生活 从此开启', // 分享标题
          link: 'http://scb.eaworks.cn/page/home', // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: 'http://pic.eaworks.cn/scbank/uploads/202284/1078189.png', // 分享图标
          success: function () {
            // 设置成功
          }
        });
        wx.onMenuShareTimeline({
          title: '四川银行-安逸生活 从此开启', // 分享标题
          link: 'http://scb.eaworks.cn/page/home', // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: 'http://pic.eaworks.cn/scbank/uploads/202284/1078189.png', // 分享图标
          success: function () {
            // 用户点击了分享后执行的回调函数
          }
        });
        wx.updateAppMessageShareData({
          title: '四川银行', // 分享标题
          desc: '安逸生活 从此开启', // 分享描述
          link: 'http://scb.eaworks.cn/page/home', // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: 'http://pic.eaworks.cn/scbank/uploads/202284/1078189.png', // 分享图标
          success: function () {
            // 设置成功
          }
        });
      });

      wx.error(function(res){
        console.info("wx error",res);
      });
    },
    async   getMerchantDetail(){
      // 获取商户的详情
      let {data} = await this.$ajax.gateway("/api/api_get_merchant_detail", {
        "METHOD": "get",
        "id": this.$route.query.merchant_id
      });
      this.MerchantDetail = data;
      this.getSubMerchantDetail();
    },
    async getSubMerchantDetail(){
      // 获取指定子商户信息
      let {data} = await this.$ajax.gateway("/api/admin_get_sub_merchant_detail", {
        "METHOD": "get",
        "sub_id":  this.$route.query.sub_id,
      });
      this.SubDetail = data;
      console.info(this.SubDetail);
    }

  }
};
</script>

<style lang="less" scoped>
.shop-wrapper {
  background: #ebebeb;
  .shop_banner {
    position: relative;
    img {
      display: block;
      width: 100%;
    }
    span {
      display: inline-block;
      width: 3.5rem;
      height: 1rem;
      position: absolute;
      top: 4rem;
      right: 0.8rem;
    }
  }
}
._right_bar_new_card{
  position: fixed;
  top: 0.3rem;
  right: 0rem;
  img {
    height: 2.7rem;
  }
}
</style>
